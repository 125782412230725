import { getDatasourceSrv } from 'app/features/plugins/datasource_srv';
import { getBackendSrv } from 'app/core/services/backend_srv';
import store from 'app/core/store';
import { SetupStep } from './types';

const step1TutorialTitle = 'Grafana fundamentals';
const step2TutorialTitle = 'Create users and teams';
const keyPrefix = 'getting.started.';
const step1Key = `${keyPrefix}${step1TutorialTitle.replace(' ', '-').trim().toLowerCase()}`;
const step2Key = `${keyPrefix}${step2TutorialTitle.replace(' ', '-').trim().toLowerCase()}`;

export const getSteps = (): SetupStep[] => [
  {
    heading: 'Bem vindo ao Grafana',
    subheading: 'As etapas abaixo irão guiá-lo para concluir rapidamente a configuração da instalação do Grafana.',
    title: 'Basico',
    info: 'As etapas abaixo irão guiá-lo para concluir rapidamente a configuração da instalação do Grafana.',
    done: false,
    cards: [
      {
        type: 'tutorial',
        heading: 'Fonte de dados e painéis',
        title: step1TutorialTitle,
        info:
          'Configure e compreenda o Grafana se não tiver experiência anterior. Este tutorial o orienta por todo o processo e cobre as etapas de “Fonte de dados” e “Quadros” à direita.',
        href: 'https://grafana.com/tutorials/grafana-fundamentals',
        icon: 'grafana',
        check: () => Promise.resolve(store.get(step1Key)),
        key: step1Key,
        done: false,
      },
      {
        type: 'docs',
        title: 'Adicione sua primeira fonte de dados',
        heading: 'fontes de dados',
        icon: 'database',
        learnHref: 'https://grafana.com/docs/grafana/latest/features/datasources/add-a-data-source',
        href: 'datasources/new',
        check: () => {
          return new Promise((resolve) => {
            resolve(
              getDatasourceSrv()
                .getMetricSources()
                .filter((item) => {
                  return item.meta.builtIn !== true;
                }).length > 0
            );
          });
        },
        done: false,
      },
      {
        type: 'docs',
        heading: 'quadros',
        title: 'Crie seu primeiro quadro',
        icon: 'apps',
        href: 'dashboard/new',
        learnHref: 'https://grafana.com/docs/grafana/latest/guides/getting_started/#create-a-dashboard',
        check: async () => {
          const result = await getBackendSrv().search({ limit: 1 });
          return result.length > 0;
        },
        done: false,
      },
    ],
  },
  {
    heading: 'Configuração concluída!',
    subheading:
      'Todas as etapas necessárias para usar o Grafana foram realizadas. Agora, execute etapas avançadas ou faça o melhor uso deste quadro inicial - ele é, afinal, um quadro totalmente personalizável - e remova esse painel.',
    title: 'Avançado',
    info: ' Gerencie seus usuários e equipes e adicione plug-ins. Essas etapas são opcionais',
    done: false,
    cards: [
      {
        type: 'tutorial',
        heading: 'Users',
        title: 'Crie usuários e equipes',
        info: 'Aprenda a organizar seus usuários em equipes e gerenciar o acesso a recursos e funções.',
        href: 'https://grafana.com/tutorials/create-users-and-teams',
        icon: 'users-alt',
        key: step2Key,
        check: () => Promise.resolve(store.get(step2Key)),
        done: false,
      },
      {
        type: 'docs',
        heading: 'plugins',
        title: 'Encontre e instale plugins',
        learnHref: 'https://grafana.com/docs/grafana/latest/plugins/installation',
        href: 'plugins',
        icon: 'plug',
        check: async () => {
          const plugins = await getBackendSrv().get('/api/plugins', { embedded: 0, core: 0 });
          return Promise.resolve(plugins.length > 0);
        },
        done: false,
      },
    ],
  },
];
