import { TimeOption } from '@grafana/data';

export const quickOptions: TimeOption[] = [
  { from: 'now-5m', to: 'now', display: 'Últimos 5 minutos', section: 3 },
  { from: 'now-15m', to: 'now', display: 'Últimos 15 minutos', section: 3 },
  { from: 'now-30m', to: 'now', display: 'Últimos 30 minutos', section: 3 },
  { from: 'now-1h', to: 'now', display: 'Última 1 hora', section: 3 },
  { from: 'now-3h', to: 'now', display: 'Últimas 3 horas', section: 3 },
  { from: 'now-6h', to: 'now', display: 'Últimas 6 horas', section: 3 },
  { from: 'now-12h', to: 'now', display: 'Últimas 12 horas', section: 3 },
  { from: 'now-24h', to: 'now', display: 'Últimas 24 horas', section: 3 },
  { from: 'now-2d', to: 'now', display: 'Últimos 2 dias', section: 3 },
  { from: 'now-7d', to: 'now', display: 'Últimos 7 dias', section: 3 },
  { from: 'now-30d', to: 'now', display: 'Últimos 30 dias', section: 3 },
  { from: 'now-90d', to: 'now', display: 'Últimos 90 dias', section: 3 },
  { from: 'now-6M', to: 'now', display: 'Últimos 6 meses', section: 3 },
  { from: 'now-1y', to: 'now', display: 'Último 1 ano', section: 3 },
  { from: 'now-2y', to: 'now', display: 'Últimos 2 anos', section: 3 },
  { from: 'now-5y', to: 'now', display: 'Últimos 5 anos', section: 3 },
];

export const otherOptions: TimeOption[] = [
  { from: 'now-1d/d', to: 'now-1d/d', display: 'Ontem', section: 3 },
  { from: 'now-2d/d', to: 'now-2d/d', display: 'Anteontem', section: 3 },
  { from: 'now-7d/d', to: 'now-7d/d', display: 'Este dia na semana passada', section: 3 },
  { from: 'now-1w/w', to: 'now-1w/w', display: 'Semana passada', section: 3 },
  { from: 'now-1M/M', to: 'now-1M/M', display: 'Mês passado', section: 3 },
  { from: 'now-1y/y', to: 'now-1y/y', display: 'Ano passado', section: 3 },
  { from: 'now/d', to: 'now/d', display: 'Hoje', section: 3 },
  { from: 'now/d', to: 'now', display: 'Hoje até agora', section: 3 },
  { from: 'now/w', to: 'now/w', display: 'Essa semana', section: 3 },
  { from: 'now/w', to: 'now', display: 'Essa semana até agora', section: 3 },
  { from: 'now/M', to: 'now/M', display: 'Esse mês', section: 3 },
  { from: 'now/M', to: 'now', display: 'Esse mês até agora', section: 3 },
  { from: 'now/y', to: 'now/y', display: 'Esse ano', section: 3 },
  { from: 'now/y', to: 'now', display: 'Esse ano até agora', section: 3 },
];
