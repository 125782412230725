import { DataFrame, FieldConfigSource, PanelPlugin } from '@grafana/data';

export interface PanelEditorTab {
  id: string;
  text: string;
  active: boolean;
  icon: string;
}

export enum PanelEditorTabId {
  Query = 'query',
  Transform = 'transform',
  Visualize = 'visualize',
  Alert = 'alert',
}

export enum DisplayMode {
  Fill = 0,
  Fit = 1,
  Exact = 2,
}

export const displayModes = [
  { value: DisplayMode.Fill, label: 'Preencher', description: 'Use todo o espaço disponível' },
  { value: DisplayMode.Fit, label: 'Ajustar', description: 'Encaixa no espaço mantendo a proporção' },
  { value: DisplayMode.Exact, label: 'Exato', description: 'Mesmo tamanho da dashboard' },
];

/** @internal */
export interface Props {
  plugin: PanelPlugin;
  config: FieldConfigSource;
  onChange: (config: FieldConfigSource) => void;
  /* Helpful for IntelliSense */
  data: DataFrame[];
}
