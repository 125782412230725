import { Field } from '../../../types/dataFrame';
import { ValueMatcherInfo } from '../../../types/transformations';
import { ValueMatcherID } from '../ids';
import { ValueMatcherOptions } from './types';

const isNullValueMatcher: ValueMatcherInfo<ValueMatcherOptions> = {
  id: ValueMatcherID.isNull,
  name: 'É nulo',
  description: 'Corresponde ao valor de determinado campo ser nulo.',
  get: () => {
    return (valueIndex: number, field: Field) => {
      const value = field.values.get(valueIndex);
      return value === null;
    };
  },
  getOptionsDisplayText: () => {
    return `Corresponde a todas as linhas em que o campo é nulo.`;
  },
  isApplicable: () => true,
  getDefaultOptions: () => ({}),
};

const isNotNullValueMatcher: ValueMatcherInfo<ValueMatcherOptions> = {
  id: ValueMatcherID.isNotNull,
  name: 'Não é nulo',
  description: 'Corresponde ao valor de determinado campo que não é nulo.',
  get: () => {
    return (valueIndex: number, field: Field) => {
      const value = field.values.get(valueIndex);
      return value !== null;
    };
  },
  getOptionsDisplayText: () => {
    return `Corresponde a todas as linhas onde o campo não é nulo.`;
  },
  isApplicable: () => true,
  getDefaultOptions: () => ({}),
};

export const getNullValueMatchers = (): ValueMatcherInfo[] => [isNullValueMatcher, isNotNullValueMatcher];
