import { PanelPlugin } from '@grafana/data';
import { TablePanel } from './TablePanel';
import { CustomFieldConfig, Options } from './types';
import { tableMigrationHandler, tablePanelChangedHandler } from './migrations';
import { TableCellDisplayMode } from '@grafana/ui';

export const plugin = new PanelPlugin<Options, CustomFieldConfig>(TablePanel)
  .setPanelChangeHandler(tablePanelChangedHandler)
  .setMigrationHandler(tableMigrationHandler)
  .setNoPadding()
  .useFieldConfig({
    useCustomConfig: (builder) => {
      builder
        .addNumberInput({
          path: 'width',
          name: 'Largura da coluna',
          settings: {
            placeholder: 'auto',
            min: 20,
            max: 300,
          },
          shouldApply: () => true,
        })
        .addRadio({
          path: 'align',
          name: 'Alinhamento da coluna',
          settings: {
            options: [
              { label: 'auto', value: null },
              { label: 'esquerda', value: 'left' },
              { label: 'centro', value: 'center' },
              { label: 'direita', value: 'right' },
            ],
          },
          defaultValue: null,
        })
        .addSelect({
          path: 'displayMode',
          name: 'Modo de exibição de célula',
          description: 'Cor do texto, plano de fundo, mostrar como medidor, etc.',
          settings: {
            options: [
              { value: TableCellDisplayMode.Auto, label: 'Auto' },
              { value: TableCellDisplayMode.ColorText, label: 'Cor do texto' },
              { value: TableCellDisplayMode.ColorBackground, label: 'Cor de fundo' },
              { value: TableCellDisplayMode.GradientGauge, label: 'Medidor de gradiente' },
              { value: TableCellDisplayMode.LcdGauge, label: 'Medidor LCD' },
              { value: TableCellDisplayMode.BasicGauge, label: 'Medidor básico' },
              { value: TableCellDisplayMode.JSONView, label: 'Visualização JSON' },
              { value: TableCellDisplayMode.Image, label: 'Imagem' },
            ],
          },
        })
        .addBooleanSwitch({
          path: 'filterable',
          name: 'Filtro de coluna',
          description: 'Habilita / desabilita filtros de campo na tabela',
          defaultValue: false,
        });
    },
  })
  .setPanelOptions((builder) => {
    builder.addBooleanSwitch({
      path: 'showHeader',
      name: 'Mostrar Cabeçalho',
      description: 'Exibir o cabeçalho da tabela ou não exibir',
      defaultValue: true,
    });
  });
