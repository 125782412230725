export enum OrgRole {
  Viewer = 'Viewer',
  Editor = 'Editor',
  Admin = 'Admin',
}

export interface DashboardAclDTO {
  id?: number;
  dashboardId?: number;
  userId?: number;
  userLogin?: string;
  userEmail?: string;
  teamId?: number;
  team?: string;
  permission?: PermissionLevel;
  role?: OrgRole;
  icon?: string;
  inherited?: boolean;
}

export interface DashboardAclUpdateDTO {
  userId?: number;
  teamId?: number;
  role?: OrgRole;
  permission?: PermissionLevel;
}

export interface DashboardAcl {
  id?: number;
  dashboardId?: number;
  userId?: number;
  userLogin?: string;
  userEmail?: string;
  teamId?: number;
  team?: string;
  permission?: PermissionLevel;
  role?: OrgRole;
  icon?: string;
  name?: string;
  inherited?: boolean;
  sortRank?: number;
  userAvatarUrl?: string;
  teamAvatarUrl?: string;
}

export interface DashboardPermissionInfo {
  value: PermissionLevel;
  label: string;
  description: string;
}

export interface NewDashboardAclItem {
  teamId: number;
  userId: number;
  role?: OrgRole;
  permission: PermissionLevel;
  type: AclTarget;
}

export enum PermissionLevel {
  View = 1,
  Edit = 2,
  Admin = 4,
}

export enum DataSourcePermissionLevel {
  Query = 1,
  Admin = 2,
}

export enum AclTarget {
  Team = 'Team',
  User = 'User',
  Viewer = 'Viewer',
  Editor = 'Editor',
}

export interface AclTargetInfo {
  value: AclTarget;
  label: string;
}

export const dataSourceAclLevels = [
  { value: DataSourcePermissionLevel.Query, label: 'Consulta', description: 'Pode consultar o banco de dados.' },
];

export const dashboardAclTargets: AclTargetInfo[] = [
  { value: AclTarget.Team, label: 'Time' },
  { value: AclTarget.User, label: 'Usuário' },
  { value: AclTarget.Viewer, label: 'Todos com a função de visualizador' },
  { value: AclTarget.Editor, label: 'Todos com papel de editor' },
];

export const dashboardPermissionLevels: DashboardPermissionInfo[] = [
  { value: PermissionLevel.View, label: 'Ver', description: 'Pode visualizar quadros.' },
  { value: PermissionLevel.Edit, label: 'Editar', description: 'Pode adicionar, editar e excluir quadros.' },
  {
    value: PermissionLevel.Admin,
    label: 'Admin',
    description: 'Pode adicionar / remover permissões e pode adicionar, editar e excluir quadros.',
  },
];

export enum TeamPermissionLevel {
  Member = 0,
  Admin = 4,
}

export interface TeamPermissionInfo {
  value: TeamPermissionLevel;
  label: string;
  description: string;
}

export const teamsPermissionLevels: TeamPermissionInfo[] = [
  { value: TeamPermissionLevel.Member, label: 'Membro', description: 'É membro da equipe' },
  {
    value: TeamPermissionLevel.Admin,
    label: 'Admin',
    description: 'Pode adicionar / remover permissões, membros e excluir equipe.',
  },
];
