import React from 'react';
import { MetricDescriptor } from '../types';
import { Icon } from '@grafana/ui';

export interface Props {
  rawQuery: string;
  lastQueryError?: string;
  metricDescriptor?: MetricDescriptor;
}

interface State {
  displayHelp: boolean;
  displaRawQuery: boolean;
}

export class Help extends React.Component<Props, State> {
  state: State = {
    displayHelp: false,
    displaRawQuery: false,
  };

  onHelpClicked = () => {
    this.setState({ displayHelp: !this.state.displayHelp });
  };

  onRawQueryClicked = () => {
    this.setState({ displaRawQuery: !this.state.displaRawQuery });
  };

  shouldComponentUpdate(nextProps: Props) {
    return nextProps.metricDescriptor !== null;
  }

  render() {
    const { displayHelp, displaRawQuery } = this.state;
    const { rawQuery, lastQueryError } = this.props;

    return (
      <>
        <div className="gf-form-inline">
          <div className="gf-form" onClick={this.onHelpClicked}>
            <label className="gf-form-label query-keyword pointer">
              Mostrar ajuda <Icon name={displayHelp ? 'angle-down' : 'angle-right'} />
            </label>
          </div>

          {rawQuery && (
            <div className="gf-form" onClick={this.onRawQueryClicked}>
              <label className="gf-form-label query-keyword">
                Consulta bruta
                <Icon
                  name={displaRawQuery ? 'angle-down' : 'angle-right'}
                  ng-show="ctrl.showHelp"
                  style={{ marginTop: '3px' }}
                />
              </label>
            </div>
          )}

          <div className="gf-form gf-form--grow">
            <div className="gf-form-label gf-form-label--grow" />
          </div>
        </div>
        {rawQuery && displaRawQuery && (
          <div className="gf-form">
            <pre className="gf-form-pre">{rawQuery}</pre>
          </div>
        )}

        {displayHelp && (
          <div className="gf-form grafana-info-box alert-info">
            <div>
              <h5>Padrões de alias</h5>Formate as chaves de legenda da maneira que desejar, usando padrões de alias.
              Formate a legenda chaves da maneira que você quiser usando padrões de alias.
              <br /> <br />
              Exemplo:
              <code>{`${'{{metric.name}} - {{metric.label.instance_name}}'}`}</code>
              <br />
              Resultado: &nbsp;&nbsp;<code>cpu/usage_time - server1-europe-west-1</code>
              <br />
              <br />
              <strong>Padrões</strong>
              <br />
              <ul>
                <li>
                  <code>{`${'{{metric.type}}'}`}</code> = tipo de métrica, por exemplo
                  compute.googleapis.com/instance/cpu/usage_time
                </li>
                <li>
                  <code>{`${'{{metric.name}}'}`}</code> = nome da parte da métrica, por exemplo instance / cpu /
                  usage_time
                </li>
                <li>
                  <code>{`${'{{metric.service}}'}`}</code> = parte do serviço da métrica, por exemplo calcular
                </li>
                <li>
                  <code>{`${'{{metric.label.label_name}}'}`}</code> = Metadados do rótulo da métrica, por exemplo
                  metric.label.instance_name
                </li>
                <li>
                  <code>{`${'{{resource.label.label_name}}'}`}</code> = Metadados do rótulo do recurso, por exemplo
                  resource.label.zone
                </li>
                <li>
                  <code>{`${'{{metadata.system_labels.name}}'}`}</code> = Rótulos do sistema de metadados, por exemplo
                  metadata.system_labels.name. Para que isso funcione, o necessário precisa ser incluído no agrupar por
                </li>
                <li>
                  <code>{`${'{{metadata.user_labels.name}}'}`}</code> = Rótulos de usuário de metadados, por exemplo
                  metadata.user_labels.name. Para que isso funcione, o precisa ser incluído no agrupar por
                </li>
                <li>
                  <code>{`${'{{bucket}}'}`}</code> = limite do balde para métricas de distribuição ao usar um mapa de
                  Grafana
                </li>
                <li>
                  <code>{`${'{{project}}'}`}</code> = O nome do projeto que foi especificado no editor de consultas
                </li>
                <li>
                  <code>{`${'{{service}}'}`}</code> = O ID de serviço que foi especificado no editor de consulta SLO
                </li>
                <li>
                  <code>{`${'{{slo}}'}`}</code> = The SLO id that was specified in the SLO query editor O id de SLO que
                  foi especificado no editor de consulta de SLO
                </li>
                <li>
                  <code>{`${'{{selector}}'}`}</code> = A função Seletor que foi especificada no editor de consulta SLO
                </li>
              </ul>
            </div>
          </div>
        )}

        {lastQueryError && (
          <div className="gf-form">
            <pre className="gf-form-pre alert alert-error">{lastQueryError}</pre>
          </div>
        )}
      </>
    );
  }
}
