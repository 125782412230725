import { Field } from '../../../types/dataFrame';
import { ValueMatcherInfo } from '../../../types/transformations';
import { ValueMatcherID } from '../ids';
import { BasicValueMatcherOptions } from './types';

const isEqualValueMatcher: ValueMatcherInfo<BasicValueMatcherOptions> = {
  id: ValueMatcherID.equal,
  name: 'É igual',
  description: 'Corresponde ao valor de determinado campo ser igual ao valor das opções.',
  get: (options) => {
    return (valueIndex: number, field: Field) => {
      const value = field.values.get(valueIndex);
      // eslint-disable-next-line eqeqeq
      return value == options.value;
    };
  },
  getOptionsDisplayText: () => {
    return `Corresponde a todas as linhas em que o campo é nulo.`;
  },
  isApplicable: () => true,
  getDefaultOptions: () => ({ value: '' }),
};

const isNotEqualValueMatcher: ValueMatcherInfo<BasicValueMatcherOptions> = {
  id: ValueMatcherID.notEqual,
  name: 'Não é igual',
  description: 'Corresponde ao valor de determinado campo que não é igual ao valor das opções.',
  get: (options) => {
    return (valueIndex: number, field: Field) => {
      const value = field.values.get(valueIndex);
      // eslint-disable-next-line eqeqeq
      return value != options.value;
    };
  },
  getOptionsDisplayText: () => {
    return `Corresponde a todas as linhas onde o campo não é nulo.`;
  },
  isApplicable: () => true,
  getDefaultOptions: () => ({ value: '' }),
};

export const getEqualValueMatchers = (): ValueMatcherInfo[] => [isEqualValueMatcher, isNotEqualValueMatcher];
