import _ from 'lodash';
import { ILocationService, IScope } from 'angular';
import { selectors } from '@grafana/e2e-selectors';

import { appEvents, coreModule } from 'app/core/core';
import { DashboardModel } from '../../state/DashboardModel';
import { CoreEvents } from 'app/types';
import { AppEvents, TimeZone } from '@grafana/data';
import { promiseToDigest } from '../../../../core/utils/promiseToDigest';
import { deleteDashboard } from 'app/features/manage-dashboards/state/actions';

export class SettingsCtrl {
  dashboard: DashboardModel;
  canSaveAs: boolean;
  canSave?: boolean;
  canDelete?: boolean;
  selectors: typeof selectors.pages.Dashboard.Settings.General;
  renderCount: number; // hack to update React when Angular changes

  /** @ngInject */
  constructor(private $scope: IScope & Record<string, any>, private $location: ILocationService) {
    // temp hack for annotations and variables editors
    // that rely on inherited scope
    $scope.dashboard = this.dashboard;
    this.canDelete = this.dashboard.meta.canSave;
    this.selectors = selectors.pages.Dashboard.Settings.General;
    this.renderCount = 0;
  }

  deleteDashboard() {
    let confirmText = '';
    let text2 = this.dashboard.title;

    if (this.dashboard.meta.provisioned) {
      appEvents.emit(CoreEvents.showConfirmModal, {
        title: 'Não é possível excluir painel provisionado',
        text: `
          Este painel é gerenciado pelo provisionamento Grafanas e não pode ser excluído. Remova o painel do
arquivo de configuração para excluí-lo.
        `,
        text2: `
          <i>
          Ver <a class="external-link" href="http://docs.grafana.org/administration/provisioning/#dashboards" target="_blank">
          documentation</a>
          para obter mais informações sobre provisionamento.</i>
          </br>
          File path: ${this.dashboard.meta.provisionedExternalId}
        `,
        text2htmlBind: true,
        icon: 'trash-alt',
        noText: 'OK',
      });
      return;
    }

    const alerts = _.sumBy(this.dashboard.panels, (panel) => {
      return panel.alert ? 1 : 0;
    });

    if (alerts > 0) {
      confirmText = 'EXCLUIR';
      text2 = `
      Este painel contém ${alerts} alertas. Excluir este painel também excluirá esses alertas`;
    }

    appEvents.emit(CoreEvents.showConfirmModal, {
      title: 'Excluir',
      text: 'Você deseja excluir este painel?',
      text2: text2,
      icon: 'trash-alt',
      confirmText: confirmText,
      yesText: 'Excluir',
      onConfirm: () => {
        this.dashboard.meta.canSave = false;
        this.deleteDashboardConfirmed();
      },
    });
  }

  deleteDashboardConfirmed() {
    promiseToDigest(this.$scope)(
      deleteDashboard(this.dashboard.uid, false).then(() => {
        appEvents.emit(AppEvents.alertSuccess, ['Quadro deletado', this.dashboard.title + ' foi deletado']);
        this.$location.url('/');
      })
    );
  }

  onFolderChange = (folder: { id: number; title: string }) => {
    this.dashboard.meta.folderId = folder.id;
    this.dashboard.meta.folderTitle = folder.title;
    this.dashboard.meta.hasUnsavedFolderChange = true;
  };

  onRefreshIntervalChange = (intervals: string[]) => {
    this.dashboard.timepicker.refresh_intervals = intervals.filter((i) => i.trim() !== '');
    this.renderCount++;
  };

  onNowDelayChange = (nowDelay: string) => {
    this.dashboard.timepicker.nowDelay = nowDelay;
    this.renderCount++;
  };

  onHideTimePickerChange = (hide: boolean) => {
    this.dashboard.timepicker.hidden = hide;
    this.renderCount++;
  };

  onTimeZoneChange = (timeZone: TimeZone) => {
    this.dashboard.timezone = timeZone;
    this.renderCount++;
  };
}

export function dashboardSettings() {
  return {
    restrict: 'E',
    templateUrl: 'public/app/features/dashboard/components/DashboardSettings/template.html',
    controller: SettingsCtrl,
    bindToController: true,
    controllerAs: 'ctrl',
    transclude: true,
    scope: { dashboard: '=' },
  };
}

coreModule.directive('dashboardSettings', dashboardSettings);
