import React from 'react';
import { appEvents } from 'app/core/core';
import { Icon } from '@grafana/ui';

export class HelpModal extends React.PureComponent {
  static tabIndex = 0;
  static shortcuts = {
    Global: [
      { keys: ['g', 'h'], description: 'Vá para o quadro inicial' },
      { keys: ['g', 'p'], description: 'Vá para o perfil' },
      { keys: ['s', 'o'], description: 'Pesquisa aberta' },
      { keys: ['esc'], description: 'Sair de editar / configurar visualizações' },
    ],
    Quadro: [
      { keys: ['mod+s'], description: 'Salvar quadro' },
      { keys: ['d', 'r'], description: 'Atualizar todos os painéis' },
      { keys: ['d', 's'], description: 'Configurações do quadro' },
      { keys: ['d', 'v'], description: 'Alternar entre modo ativo / visualização' },
      { keys: ['d', 'k'], description: 'Alternar modo quiosque (oculta a navegação superior)' },
      { keys: ['d', 'E'], description: 'Expandir todas as linhas' },
      { keys: ['d', 'C'], description: 'Recolher todas as linhas' },
      { keys: ['d', 'a'], description: 'Alternar painéis de ajuste automático (recurso experimental)' },
      { keys: ['mod+o'], description: 'Alternar cruz de gráfico compartilhado' },
      { keys: ['d', 'l'], description: 'Alternar todas as legendas do painel' },
    ],
    'Painel em foco': [
      { keys: ['e'], description: 'Alternar visualização de edição do painel' },
      { keys: ['v'], description: 'Alternar visualização do painel em tela cheias' },
      { keys: ['p', 's'], description: 'Abrir painel de compartilhamento modal' },
      { keys: ['p', 'd'], description: 'Duplicar Quadro' },
      { keys: ['p', 'r'], description: 'Remover Quadro' },
      { keys: ['p', 'l'], description: 'Alternar legenda do painel' },
    ],
    'Alcance de tempo': [
      { keys: ['t', 'z'], description: 'Zoom out no alcance de tempo' },
      {
        keys: ['t', '←'],
        description: 'Mover o alcance de tempo para trás',
      },
      {
        keys: ['t', '→'],
        description: 'Mover o alcance de tempo para frente',
      },
    ],
  };

  dismiss() {
    appEvents.emit('hide-modal');
  }

  render() {
    return (
      <div className="modal-body">
        <div className="modal-header">
          <h2 className="modal-header-title">
            <Icon name="keyboard" size="lg" />
            <span className="p-l-1">Atalhos</span>
          </h2>
          <a className="modal-header-close" onClick={this.dismiss}>
            <Icon name="times" style={{ margin: '3px 0 0 0' }} />
          </a>
        </div>

        <div className="modal-content help-modal">
          <p className="small" style={{ position: 'absolute', top: '13px', right: '44px' }}>
            <span className="shortcut-table-key">mod</span> =
            <span className="muted">CTRL no Windows ou Linux e tecla CMD no Mac</span>
          </p>

          {Object.entries(HelpModal.shortcuts).map(([category, shortcuts], i) => (
            <div className="shortcut-category" key={i}>
              <table className="shortcut-table">
                <tbody>
                  <tr>
                    <th className="shortcut-table-category-header" colSpan={2}>
                      {category}
                    </th>
                  </tr>
                  {shortcuts.map((shortcut, j) => (
                    <tr key={`${i}-${j}`}>
                      <td className="shortcut-table-keys">
                        {shortcut.keys.map((key, k) => (
                          <span className="shortcut-table-key" key={`${i}-${j}-${k}`}>
                            {key}
                          </span>
                        ))}
                      </td>
                      <td className="shortcut-table-description">{shortcut.description}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ))}

          <div className="clearfix" />
        </div>
      </div>
    );
  }
}
