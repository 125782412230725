import { PanelPlugin, LogsSortOrder } from '@grafana/data';
import { Options } from './types';
import { LogsPanel } from './LogsPanel';

export const plugin = new PanelPlugin<Options>(LogsPanel).setPanelOptions((builder) => {
  builder
    .addBooleanSwitch({
      path: 'showTime',
      name: 'Tempo',
      description: '',
      defaultValue: false,
    })
    .addBooleanSwitch({
      path: 'showLabels',
      name: 'Etiquetas exclusivas',
      description: '',
      defaultValue: false,
    })
    .addBooleanSwitch({
      path: 'wrapLogMessage',
      name: 'Quebra de linhas',
      description: '',
      defaultValue: false,
    })
    .addRadio({
      path: 'sortOrder',
      name: 'Ordenação',
      description: '',
      settings: {
        options: [
          { value: LogsSortOrder.Descending, label: 'Descendente' },
          { value: LogsSortOrder.Ascending, label: 'Ascendente' },
        ],
      },
      defaultValue: LogsSortOrder.Descending,
    });
});
