import React from 'react';
import { DataTransformerID, standardTransformers, TransformerRegistyItem, TransformerUIProps } from '@grafana/data';
import { MergeTransformerOptions } from '@grafana/data/src/transformations/transformers/merge';

export const MergeTransformerEditor: React.FC<TransformerUIProps<MergeTransformerOptions>> = ({
  input,
  options,
  onChange,
}) => {
  return null;
};

export const mergeTransformerRegistryItem: TransformerRegistyItem<MergeTransformerOptions> = {
  id: DataTransformerID.merge,
  editor: MergeTransformerEditor,
  transformation: standardTransformers.mergeTransformer,
  name: 'Unir',
  description: `Mescla muitas séries / tabelas e retorna uma única tabela onde os valores mescláveis ​​serão combinados na mesma linha.
  Útil para mostrar várias séries, tabelas ou uma combinação de ambas visualizadas em uma tabela.`,
};
