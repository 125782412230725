import { PanelPlugin } from '@grafana/data';
import { LiveChannelEditor } from './LiveChannelEditor';
import { LivePanel } from './LivePanel';
import { LivePanelOptions, MessageDisplayMode } from './types';

export const plugin = new PanelPlugin<LivePanelOptions>(LivePanel).setPanelOptions((builder) => {
  builder.addCustomEditor({
    category: ['Channel'],
    id: 'channel',
    path: 'channel',
    name: 'Canal',
    editor: LiveChannelEditor,
    defaultValue: {},
  });

  builder
    .addRadio({
      path: 'message',
      name: 'Mostrar mensagem',
      description: 'Mostra a última mensagem recebida neste canal',
      settings: {
        options: [
          { value: MessageDisplayMode.Raw, label: 'Texto bruto' },
          { value: MessageDisplayMode.JSON, label: 'JSON' },
          { value: MessageDisplayMode.Auto, label: 'Auto' },
          { value: MessageDisplayMode.None, label: 'Nenhum' },
        ],
      },
      defaultValue: MessageDisplayMode.JSON,
    })
    .addBooleanSwitch({
      path: 'publish',
      name: 'Mostrar Publicação',
      description: 'Exibir um formulário para publicar valores',
      defaultValue: false,
    });
});
