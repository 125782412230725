import angular from 'angular';
import _ from 'lodash';

import { getPluginSettings } from './PluginSettingsCache';
import { PluginMeta } from '@grafana/data';
import { NavModelSrv } from 'app/core/core';
import { GrafanaRootScope } from 'app/routes/GrafanaCtrl';
import { AppEvents } from '@grafana/data';
import { promiseToDigest } from '../../core/utils/promiseToDigest';

export class AppPageCtrl {
  page: any;
  pluginId: any;
  appModel: any;
  navModel: any;

  /** @ngInject */
  constructor(private $routeParams: any, private $rootScope: GrafanaRootScope, private navModelSrv: NavModelSrv) {
    this.pluginId = $routeParams.pluginId;

    promiseToDigest($rootScope)(
      Promise.resolve(getPluginSettings(this.pluginId))
        .then((settings) => {
          this.initPage(settings);
        })
        .catch((err) => {
          this.$rootScope.appEvent(AppEvents.alertError, ['Plugin desconhecido']);
          this.navModel = this.navModelSrv.getNotFoundNav();
        })
    );
  }

  initPage(app: PluginMeta) {
    this.appModel = app;
    this.page = _.find(app.includes, { slug: this.$routeParams.slug });

    if (!this.page) {
      this.$rootScope.appEvent(AppEvents.alertError, ['Página da aplicação não encontrada']);
      this.navModel = this.navModelSrv.getNotFoundNav();
      return;
    }
    if (app.type !== 'app' || !app.enabled) {
      this.$rootScope.appEvent(AppEvents.alertError, ['Aplicação não habilitada']);
      this.navModel = this.navModelSrv.getNotFoundNav();
      return;
    }

    const pluginNav = this.navModelSrv.getNav('plugin-page-' + app.id);

    this.navModel = {
      main: {
        img: app.info.logos.large,
        subTitle: app.name,
        url: '',
        text: this.page.name,
        breadcrumbs: [{ title: app.name, url: pluginNav.main.url }],
      },
    };
  }
}

angular.module('grafana.controllers').controller('AppPageCtrl', AppPageCtrl);
