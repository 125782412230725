import React from 'react';
import { HttpSettingsBaseProps } from './types';
import { Switch } from '../Forms/Legacy/Switch/Switch';

export const HttpProxySettings: React.FC<HttpSettingsBaseProps> = ({ dataSourceConfig, onChange }) => {
  return (
    <>
      <div className="gf-form-inline">
        <Switch
          label="TLS Client Auth"
          labelClass="width-13"
          checked={dataSourceConfig.jsonData.tlsAuth || false}
          onChange={(event) => onChange({ ...dataSourceConfig.jsonData, tlsAuth: event!.currentTarget.checked })}
        />

        <Switch
          label="Com CA Cert"
          labelClass="width-13"
          checked={dataSourceConfig.jsonData.tlsAuthWithCACert || false}
          onChange={(event) =>
            onChange({ ...dataSourceConfig.jsonData, tlsAuthWithCACert: event!.currentTarget.checked })
          }
          tooltip="Necessário para verificar certificados TLS autoassinados"
        />
      </div>
      <div className="gf-form-inline">
        <Switch
          label="Skip TLS Verify"
          labelClass="width-13"
          checked={dataSourceConfig.jsonData.tlsSkipVerify || false}
          onChange={(event) => onChange({ ...dataSourceConfig.jsonData, tlsSkipVerify: event!.currentTarget.checked })}
        />
      </div>
      <div className="gf-form-inline">
        <Switch
          label="Identificador Forward OAuth"
          labelClass="width-13"
          checked={dataSourceConfig.jsonData.oauthPassThru || false}
          onChange={(event) => onChange({ ...dataSourceConfig.jsonData, oauthPassThru: event!.currentTarget.checked })}
          tooltip="Encaminhe a identidade OAuth upstream do usuário para a fonte de dados (seu token de acesso é repassado)."
        />
      </div>
    </>
  );
};
