import { Field, FieldType } from '../../../types/dataFrame';
import { ValueMatcherInfo } from '../../../types/transformations';
import { ValueMatcherID } from '../ids';
import { BasicValueMatcherOptions } from './types';

const isGreaterValueMatcher: ValueMatcherInfo<BasicValueMatcherOptions<number>> = {
  id: ValueMatcherID.greater,
  name: 'É maior',
  description: 'Corresponde quando o valor do campo é maior que a opção.',
  get: (options) => {
    return (valueIndex: number, field: Field) => {
      const value = field.values.get(valueIndex);
      if (isNaN(value)) {
        return false;
      }
      return value > options.value;
    };
  },
  getOptionsDisplayText: (options) => {
    return `Corresponde a todas as linhas em que o valor do campo é maior que: ${options.value}.`;
  },
  isApplicable: (field) => field.type === FieldType.number,
  getDefaultOptions: () => ({ value: 0 }),
};

const isGreaterOrEqualValueMatcher: ValueMatcherInfo<BasicValueMatcherOptions<number>> = {
  id: ValueMatcherID.greaterOrEqual,
  name: 'É maior ou igual',
  description: 'Corresponde quando o valor do campo é menor ou maior que a opção.',
  get: (options) => {
    return (valueIndex: number, field: Field) => {
      const value = field.values.get(valueIndex);
      if (isNaN(value)) {
        return false;
      }
      return value >= options.value;
    };
  },
  getOptionsDisplayText: (options) => {
    return `Corresponde a todas as linhas onde o valor do campo é menor ou maior que: ${options.value}.`;
  },
  isApplicable: (field) => field.type === FieldType.number,
  getDefaultOptions: () => ({ value: 0 }),
};

const isLowerValueMatcher: ValueMatcherInfo<BasicValueMatcherOptions<number>> = {
  id: ValueMatcherID.lower,
  name: 'É inferior',
  description: 'Corresponde quando o valor do campo é inferior à opção.',
  get: (options) => {
    return (valueIndex: number, field: Field) => {
      const value = field.values.get(valueIndex);
      if (isNaN(value)) {
        return false;
      }
      return value < options.value;
    };
  },
  getOptionsDisplayText: (options) => {
    return `Corresponde a todas as linhas em que o valor do campo é inferior a: ${options.value}.`;
  },
  isApplicable: (field) => field.type === FieldType.number,
  getDefaultOptions: () => ({ value: 0 }),
};

const isLowerOrEqualValueMatcher: ValueMatcherInfo<BasicValueMatcherOptions<number>> = {
  id: ValueMatcherID.lowerOrEqual,
  name: 'É menor ou igual',
  description: 'Corresponde quando o valor do campo é menor ou igual à opção.',
  get: (options) => {
    return (valueIndex: number, field: Field) => {
      const value = field.values.get(valueIndex);
      if (isNaN(value)) {
        return false;
      }
      return value <= options.value;
    };
  },
  getOptionsDisplayText: (options) => {
    return `Corresponde a todas as linhas onde o valor do campo é menor ou igual a: ${options.value}.`;
  },
  isApplicable: (field) => field.type === FieldType.number,
  getDefaultOptions: () => ({ value: 0 }),
};

export const getNumericValueMatchers = (): ValueMatcherInfo[] => [
  isGreaterValueMatcher,
  isGreaterOrEqualValueMatcher,
  isLowerValueMatcher,
  isLowerOrEqualValueMatcher,
];
