import React from 'react';

const PermissionsInfo = () => (
  <div>
    <h5>O que são permissões?</h5>
    <p>
      Um modelo de lista de controle de acesso (ACL) é usado para limitar o acesso às pastas do quadro. Um usuário ou
      uma equipe podem ser atribuídos permissões para uma pasta ou para um único quadro.
    </p>
  </div>
);

export default PermissionsInfo;
