import React, { useState, useCallback } from 'react';
import { css, cx } from 'emotion';
import { DataSourceSettings, SelectableValue } from '@grafana/data';
import { BasicAuthSettings } from './BasicAuthSettings';
import { HttpProxySettings } from './HttpProxySettings';
import { TLSAuthSettings } from './TLSAuthSettings';
import { CustomHeadersSettings } from './CustomHeadersSettings';
import { Select } from '../Forms/Legacy/Select/Select';
import { Input } from '../Forms/Legacy/Input/Input';
import { Switch } from '../Forms/Legacy/Switch/Switch';
import { Icon } from '../Icon/Icon';
import { FormField } from '../FormField/FormField';
import { InlineFormLabel } from '../FormLabel/FormLabel';
import { TagsInput } from '../TagsInput/TagsInput';
import { SigV4AuthSettings } from './SigV4AuthSettings';
import { useTheme } from '../../themes';
import { HttpSettingsProps } from './types';

const ACCESS_OPTIONS: Array<SelectableValue<string>> = [
  {
    label: 'Servidor (padrão)',
    value: 'proxy',
  },
  {
    label: 'Navegador',
    value: 'direct',
  },
];

const DEFAULT_ACCESS_OPTION = {
  label: 'Servidor (padrão)',
  value: 'proxy',
};

const HttpAccessHelp = () => (
  <div className="grafana-info-box m-t-2">
    <p>
      O modo de acesso controla como as solicitações à fonte de dados serão tratadas.
      <strong>
        &nbsp;<i>Servidor</i>
      </strong>{' '}
      deve ser a forma preferida se nada mais for declarado.
    </p>
    <div className="alert-title">Modo de acesso ao servidor (padrão):</div>
    <p>
      Todas as solicitações serão feitas do navegador para o backend / servidor Grafana que, por sua vez, encaminhará as
      solicitações para a fonte de dados e, com isso, contornar os possíveis requisitos de Compartilhamento de recursos
      entre origens (CORS). O URL precisa para ser acessível a partir do backend / servidor da Grafana se você
      selecionar este modo de acesso.
    </p>
    <div className="alert-title">Modo de acesso do navegador:</div>
    <p>
      Todas as solicitações serão feitas do navegador diretamente para o banco de dados e podem estar sujeitas ao
      recurso de origem cruzada Requisitos de compartilhamento (CORS). O URL precisa estar acessível a partir do
      navegador se você selecionar este modo de acesso.
    </p>
  </div>
);

export const DataSourceHttpSettings: React.FC<HttpSettingsProps> = (props) => {
  const { defaultUrl, dataSourceConfig, onChange, showAccessOptions, sigV4AuthToggleEnabled } = props;
  let urlTooltip;
  const [isAccessHelpVisible, setIsAccessHelpVisible] = useState(false);
  const theme = useTheme();

  const onSettingsChange = useCallback(
    (change: Partial<DataSourceSettings<any, any>>) => {
      onChange({
        ...dataSourceConfig,
        ...change,
      });
    },
    [dataSourceConfig, onChange]
  );

  switch (dataSourceConfig.access) {
    case 'direct':
      urlTooltip = (
        <>
          Seu método de acesso é <em>Navegador</em>, isso significa que o URL precisa estar acessível a partir do
          navegador.
        </>
      );
      break;
    case 'proxy':
      urlTooltip = (
        <>
          Seu método de acesso é <em>Servidor</em>, isso significa que a URL precisa ser acessível a partir do Grafana.
        </>
      );
      break;
    default:
      urlTooltip = 'Specify a complete HTTP URL (for example http://your_server:8080)';
  }

  const accessSelect = (
    <Select
      width={20}
      options={ACCESS_OPTIONS}
      value={ACCESS_OPTIONS.filter((o) => o.value === dataSourceConfig.access)[0] || DEFAULT_ACCESS_OPTION}
      onChange={(selectedValue) => onSettingsChange({ access: selectedValue.value })}
    />
  );

  const isValidUrl = /^(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?$/.test(
    dataSourceConfig.url
  );

  const notValidStyle = css`
    box-shadow: inset 0 0px 5px ${theme.palette.red};
  `;

  const inputStyle = cx({ [`width-20`]: true, [notValidStyle]: !isValidUrl });

  const urlInput = (
    <Input
      className={inputStyle}
      placeholder={defaultUrl}
      value={dataSourceConfig.url}
      onChange={(event) => onSettingsChange({ url: event.currentTarget.value })}
    />
  );

  return (
    <div className="gf-form-group">
      <>
        <h3 className="page-heading">HTTP</h3>
        <div className="gf-form-group">
          <div className="gf-form">
            <FormField label="URL" labelWidth={11} tooltip={urlTooltip} inputEl={urlInput} />
          </div>

          {showAccessOptions && (
            <>
              <div className="gf-form-inline">
                <div className="gf-form">
                  <FormField label="Acesso" labelWidth={11} inputWidth={20} inputEl={accessSelect} />
                </div>
                <div className="gf-form">
                  <label
                    className="gf-form-label query-keyword pointer"
                    onClick={() => setIsAccessHelpVisible((isVisible) => !isVisible)}
                  >
                    Ajuda&nbsp;
                    <Icon name={isAccessHelpVisible ? 'angle-down' : 'angle-right'} style={{ marginBottom: 0 }} />
                  </label>
                </div>
              </div>
              {isAccessHelpVisible && <HttpAccessHelp />}
            </>
          )}
          {dataSourceConfig.access === 'proxy' && (
            <div className="gf-form">
              <InlineFormLabel
                width={11}
                tooltip="Grafana Proxy exclui cookies encaminhados por padrão. Especifique os cookies por nome que devem ser encaminhados ao banco de dados."
              >
                Cookies na lista branca
              </InlineFormLabel>
              <TagsInput
                tags={dataSourceConfig.jsonData.keepCookies}
                onChange={(cookies) =>
                  onSettingsChange({ jsonData: { ...dataSourceConfig.jsonData, keepCookies: cookies } })
                }
                width={20}
              />
            </div>
          )}
        </div>
      </>

      <>
        <h3 className="page-heading">Auth</h3>
        <div className="gf-form-group">
          <div className="gf-form-inline">
            <Switch
              label="Autenticação básica"
              labelClass="width-13"
              checked={dataSourceConfig.basicAuth}
              onChange={(event) => {
                onSettingsChange({ basicAuth: event!.currentTarget.checked });
              }}
            />
            <Switch
              label="Com credenciais"
              labelClass="width-13"
              checked={dataSourceConfig.withCredentials}
              onChange={(event) => {
                onSettingsChange({ withCredentials: event!.currentTarget.checked });
              }}
              tooltip="Se credenciais como cookies ou cabeçalhos de autenticação devem ser enviadas com solicitações entre sites."
            />
          </div>

          {sigV4AuthToggleEnabled && (
            <div className="gf-form-inline">
              <Switch
                label="Autenticação SigV4"
                labelClass="width-13"
                checked={dataSourceConfig.jsonData.sigV4Auth || false}
                onChange={(event) => {
                  onSettingsChange({
                    jsonData: { ...dataSourceConfig.jsonData, sigV4Auth: event!.currentTarget.checked },
                  });
                }}
              />
            </div>
          )}

          {dataSourceConfig.access === 'proxy' && (
            <HttpProxySettings
              dataSourceConfig={dataSourceConfig}
              onChange={(jsonData) => onSettingsChange({ jsonData })}
            />
          )}
        </div>
        {dataSourceConfig.basicAuth && (
          <>
            <h6>Detalhes básicos de autenticação</h6>
            <div className="gf-form-group">
              <BasicAuthSettings {...props} />
            </div>
          </>
        )}

        {dataSourceConfig.jsonData.sigV4Auth && <SigV4AuthSettings {...props} />}

        {(dataSourceConfig.jsonData.tlsAuth || dataSourceConfig.jsonData.tlsAuthWithCACert) && (
          <TLSAuthSettings dataSourceConfig={dataSourceConfig} onChange={onChange} />
        )}

        {dataSourceConfig.access === 'proxy' && (
          <CustomHeadersSettings dataSourceConfig={dataSourceConfig} onChange={onChange} />
        )}
      </>
    </div>
  );
};
